<template>
  <div class="w100s" style="height: 800px;">

    <div style="padding: 1rem 0px;
    font-size: 1rem;
    text-align: center;
    background-color: #1452ff;
    color: #fff;">{{$t('lang.141')}}</div>

    <div style="    font-size: 1rem;
    font-weight: bold;
    padding: 1rem;display: flex;    flex-direction: row;
    justify-content: space-between;
    align-items: center;">
      <div style="font-size: 20px;">
        {{$t('lang.142')}} {{addressxx.lxqd_cs}} {{$t('lang.143')}}
      </div>
      <div>
        <div class="qiandao" @click="sign()">{{$t('lang.144')}}</div>
      </div>
    </div>

    <div class="lateral-sliding" style="padding: 1rem;">
      <div :class="addressxx.lxqd_cs > index ? 'lateral-sliding-items':'lateral-sliding-item'" v-for="(item,index) in items" :key="index">
        <div class="each-img">
          <div style="padding: 0.8rem;font-size: 1.2rem" v-if="addressxx.lxqd_cs > index">{{item}} {{$t('lang.145')}}</div>
          <div style="padding: 0.8rem;font-size: 1.2rem" v-else >{{item}} {{$t('lang.145')}}</div>
          <div>
            <img v-if="addressxx.lxqd_cs > index" src="@/assets/image/lotter3.png" >
            <img v-else src="@/assets/image/lotter2.png" >
          </div>
        </div>
      </div>
    </div>


    <div>
<!--      <div style="color: #c5cdff;text-align: center;margin-bottom: 1rem;font-size: 1.5rem;">lucky spin</div>-->
      <div style=" padding: 10px 45px;">
        <LuckyWheel
            ref="myLucky"
            height="300px"
            width="300px"
            :prizes="prizes"
            :blocks="blocks"
            :buttons="buttons"
            @start="startCallback"
            @end="endCallback"
        />
      </div>

    </div>

    <div style="  border: 4px solid;
  border-image-source: linear-gradient(to right, #8f41e9, #578aef);
  border-image-slice: 1;margin: 1rem;
    text-align: center;">
      {{$t('lang.146')}}
    </div>
  </div>

</template>

<script>

import { Dialog } from 'vant';
import axios from "@/utils/axios";
import Web3 from "web3";
import bl1 from "@/assets/image/lb1.png";
import bl2 from "@/assets/image/lb2.png";
import bl3 from "@/assets/image/lb3.png";
import zpt from "@/assets/image/zpt1.png";
import zpan from "@/assets/image/zpan.png";
// import axios from "@/utils/axios";
import { Toast } from 'vant';
import { formatDate } from '@/utils';
import {getAccounts} from "@/utils/Mapping";
export default {
  name: "index",
  inject: ['reload'],
  data() {
    return {
      items: 7,
      getcashflow:[],
      addressxx:{},
      defaultAddress:'',
      usdtmoney:'',
      current: 0,
      value1:1,
      dh:0,
      images: [
        bl1,
        bl2,
        bl3,
      ],
      activeNames: ['0'],
      activeName: ['0'],
      activeNamee: ['0'],
      showPopover: false,
      actions: [
        { text: 'Engilsh',key:'gb' },
        { text: 'Chinese',key:'cn'  },
        { text: 'Japan',key:'jp'  },
        { text: 'Spain',key:'es'  },
        { text: 'Vietnam',key:'vn'  },
      ],
      dataList: [
        {
          address: "",
          balance: "0"
        }
      ],
      blocks: [{ padding: '40px',imgs: [{
          src: zpt,
          width: '100%',
          height: '100%'
        }] }],
      prizes: [],
      buttons: [
        {
          radius: '45%',
          imgs: [{
            src: zpan,
            width: '80%',
            top: '-130%'
          }],
          fonts:[{text:'start',top: '-40%'}]
        }
      ],
      zjid:0,
    };
  },
  mounted() {
  },
  watch: {

  },
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },

  //初始化
  async created(){
    //网络添加切换
    // await this.getChainId()
    await this.initWeb3Account()
  },

  methods: {
    onChange(index) {
      this.current = index;
    },
    sign() {
      let that =this
      axios.post('/index/qiandao', {address: this.defaultAddress})
          .then(function (response) {
            console.log('qiandao',response.data.code);
            if(response.data.code == 1){
              Dialog({ message: that.$t('lang.147'),confirmButtonText:that.$t('lang.149')});
            }else {
              Dialog({ message: that.$t('lang.148'),confirmButtonText:that.$t('lang.149')});

            }
          })
      this.reload();
    },
    async chushi(){

    },
    // 点击抽奖按钮会触发star回调
    startCallback () {
      // 调用抽奖组件的play方法开始游戏
      // 模拟调用接口异步抽奖
      let that = this
      axios.post('/index/choujiang', {address: this.defaultAddress})
          .then(function (response) {
            console.log('choujiang',response.data.code);
            if(response.data.code == 1){
              that.zjid = response.data.data.zjid
              that.$refs.myLucky.play()
              setTimeout(() => {
                // 假设后端返回的中奖索引是0
                const index = that.zjid-1
                // 调用stop停止旋转并传递中奖索引
                that.$refs.myLucky.stop(index)
              }, 3000)
            }else {
              Dialog({ message: that.$t('lang.148'),confirmButtonText:that.$t('lang.149')});
            }
          })

    },
    // 抽奖结束会触发end回调
    endCallback (prize) {
      Dialog({ message: prize.fonts[0].text,confirmButtonText:this.$t('lang.149')});
      this.reload();
    },
    async initWeb3Account() {
      console.log("initWeb3Account 方法");
      // 检查新版MetaMask
      if (window.ethereum) {
        this.provider = window.ethereum;
        try {
          // 请求用户账号授权
          await window.ethereum.enable();
        } catch (error) {
          // 用户拒绝了访问
          console.log("User denied account access");
        }
      }
      // 老版 MetaMask
      else if (window.web3) {
        this.provider = window.web3.currentProvider;
      }
      // 如果没有注入的web3实例，回退到使用 Ganache
      else {
        this.provider = new Web3.providers.HttpProvider("http://127.0.0.1:7545");
      }
      getAccounts().then(accs => {
        this.defaultAddress = accs
        console.log(accs)
          let that = this
          axios.post('/index/getaddressxx',{address: this.defaultAddress,chainId:this.$store.state.daou.chainId,})
              .then(function (response) {
                console.log('getaddressxx',response.data);
                that.addressxx = response.data.data
              })
          axios.post('/index/getcashflow',{address: this.defaultAddress,chainId:this.$store.state.daou.chainId,})
              .then(function (response) {
                console.log('getaddressxx',response.data);
                that.getcashflow = response.data.data
              })
        axios.post('/index/get_jppz', {address: this.defaultAddress,chainId:this.$store.state.daou.chainId})
            .then(function (response) {
              if(response.data.code == 1){
                let prize=[]
                response.data.data.map(item => {
                  console.log("map",item.id)
                  let cjxx =
                      {fonts: [{ text: item.name, top: '10%' ,fontSize:'16px',fontWeight:'600',fontColor:item.id % 2 === 0 ?'#7021E8' : '#E9E0FF'}], background: item.id % 2 === 0 ?'#E9E0FF' : '#7021E8' ,imgs: [{
                          src: 'https://ulicai.bianceok.info'+item.img,
                          width: '32px',
                          height: '32px',
                          top:'25px'
                        }]}
                  prize.push(cjxx)
                })
                that.prizes = prize
                console.log("prizes",that.prizes);
              }
            })
      })


    },

    onSelect(action) {
      console.log(action)
      this.$i18n.locale = action.key
      localStorage.setItem('locale',action.key)
      this.reload();
    },
    async canyu(){
      Toast("需要大于100USDT");
      console.log('canyu');
    },
    async getChainId(){
      let id = window.ethereum.chainId;
      console.log(id)
      if(id != '0x38'){
        window.ethereum.request({
          method: 'wallet_addEthereumChain', // Metamask的api名称
          params: [{
            chainId: "0x38", // 网络id，16进制的字符串
            chainName: "BNB Smart Chain Mainnet", // 添加到钱包后显示的网络名称
            rpcUrls: [
              'https://bsc-dataseed1.ninicoin.io/', // rpc地址
            ],
            iconUrls: [
              'https://testnet.hecoinfo.com/favicon.png' // 网络的图标，暂时没看到在哪里会显示
            ],
            blockExplorerUrls: [
              'https://bscscan.com/' // 网络对应的区块浏览器
            ],
            nativeCurrency: {  // 网络主币的信息
              name: 'bnb',
              symbol: 'bnb',
              decimals: 18
            }
          }]
        });
        // window.location.reload();
        console.log('no')
      }else {
        console.log('yes')
      }
      //！链id不是马上拿到的，如果通过链id来判断是不是主网的方式，请注意异步
    },

  },
  filters: {
    ellipsis(value) {
      let len = value.length;
      if (!value) return "";
      if (value.length > 8) {
        return value.substring(0, 4) + "..." + value.substring(len - 4, len);
      }
      return value;
    },
    formatDate(time) {
      time = time * 1000
      var date = new Date(time);
      return formatDate(date, 'yyyy-MM-dd hh:mm');
    }
  },
}
</script>
<style>
.jl{
  color: #fff;
  background-color: #2f33b2;
  margin-bottom: 0.5rem;
  font-size: .9rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.duihuan{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(255, 255, 255);
  border-bottom: 1px solid;
  margin-bottom: 1.5rem;
}
.kkk{
  background: #2f33b2;
  padding: 0.3rem;
  width: 8rem;
  text-align: center;
  border-radius: 0.5rem;
}
.cyan{
  background-color: #2f33b2;
  /* padding: 0.5rem; */
  width: 6rem;
  text-align: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.ljqb{
  border-radius: 0.5rem;
  background-color: #2f33b2;
  padding: 0.5rem;
  color: #fff;
  font-size: 0.5rem;
}
.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 5px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.1);
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}
.van-cell {
  color: #fff;
  background-color: #1352ff;
}
.van-collapse-item__content {
  color: #fff;
  background-color: #2f33b2;
}
.van-cell--clickable:active {
  background-color: #2f33b2;
}
.van-collapse-item {
  position: relative;
  background: #2f33b2;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
}

.van-hairline--top-bottom {
  /*position: relative;*/
  margin-top: 1rem;
}
</style>
<style scoped>
.van-cell {
  color: #fff;
  background-color: #1352ff;
}
.van-collapse-item__content {
  color: #fff;
  background-color: #1352ff;
}
.van-cell__right-icon {
  margin-left: 4px;
  color: #ffffff;
}
.qiandao{
  background-color: #1452ff;
  padding: 0.3rem;
  font-size: 1rem;
  color: #fff;
  border-radius: 1rem;
  width: 100px;
  text-align: center;
}


.lateral-sliding {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
}
.lateral-sliding-item {
  display: flex;
  margin-right: 8px;
  background-color: #dae7f6;
  border-radius: 0.5rem;
  text-align: center;
}
.lateral-sliding-items {
  display: flex;
  margin-right: 8px;
  background-color: #a2b5fe;
  border-radius: 0.5rem;
  text-align: center;
}
.each-img {
  width: 6rem;
  height: 8rem;
  font-size: 1rem;
}

</style>
